<template>
  <section class="py-safe h-screen bg-gray-900 py-8 lg:py-0">
    <div class="lg:flex">
      <!-- Welcome Section with Branding -->
      <div
        class="hidden w-full max-w-md bg-indigo-600 p-12 lg:block lg:h-screen">
        <div class="mb-8 flex items-center space-x-4">
          <!-- Replace with GameVerse logo if available -->
          <a
            href="https://gameverse.app"
            class="flex items-center text-2xl font-semibold text-white">
            <img
              class="mr-2 h-8 w-8"
              src="~/assets/img/logos/gameverse-white.svg" />
            GameVerse
          </a>
        </div>
        <!-- Description and Features of GameVerse -->
        <div class="block rounded-lg bg-indigo-500 p-8 text-white">
          <h3 class="mb-1 text-2xl font-semibold">Welcome to GameVerse</h3>
          <p class="mb-4 font-light text-indigo-100 sm:text-lg">
            Find your perfect gaming partners!
          </p>
          <!-- Feature List -->
          <ul role="list" class="space-y-4 text-left">
            <li class="flex items-center space-x-3">
              <svg
                class="h-5 w-5 flex-shrink-0 text-green-300"
                fill="currentColor"
                viewBox="0 0 20 20">
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>AI-powered matchmaking</span>
            </li>
            <li class="flex items-center space-x-3">
              <svg
                class="h-5 w-5 flex-shrink-0 text-green-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Connect your gaming platforms</span>
            </li>
            <li class="flex items-center space-x-3">
              <svg
                class="h-5 w-5 flex-shrink-0 text-green-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Free and easy to use</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- Registration Form Section -->
      <div
        class="mx-auto flex items-center justify-center px-4 md:w-[42rem] md:px-8 xl:px-0">
        <div class="w-full">
          <div
            class="mb-8 flex items-center justify-center space-x-4 lg:hidden">
            <a href="#" class="flex items-center text-2xl font-semibold">
              <img
                alt="GameVerse"
                class="mr-2 h-8 w-8"
                src="~/assets/img/logos/gameverse-white.svg" />
              <span class="text-white">GameVerse</span>
            </a>
          </div>
          <ol
            class="mb-6 flex items-center text-center text-sm font-medium text-gray-400 sm:text-base lg:mb-12">
            <li
              :class="{ 'text-indigo-600': currentStep === 1 }"
              class="after:border-1 flex items-center after:mx-6 after:hidden after:h-1 after:w-12 after:border-b after:border-gray-700 sm:after:inline-block sm:after:content-[''] xl:after:mx-10">
              <div
                class="flex items-center fill-indigo-600 after:mx-2 after:font-light after:text-gray-500 after:content-['/'] sm:block sm:after:hidden">
                <svg
                  class="mr-2 h-4 w-4 sm:mx-auto sm:mb-2 sm:h-6 sm:w-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                Profile <span class="hidden sm:inline-flex">Info</span>
              </div>
            </li>
            <li
              :class="{ 'text-indigo-600': currentStep === 2 }"
              class="after:border-1 flex items-center after:mx-6 after:hidden after:h-1 after:w-12 after:border-b after:border-gray-700 after:content-[''] sm:after:inline-block xl:after:mx-10">
              <div
                class="flex items-center after:mx-2 after:font-light after:text-gray-500 after:content-['/'] sm:block sm:after:hidden">
                <div class="mr-2 sm:mx-auto sm:mb-2">2</div>
                Connect your
                <span class="hidden sm:inline-flex">Platforms</span>
              </div>
            </li>
            <li
              :class="{ 'text-indigo-600': currentStep === 3 }"
              class="flex items-center sm:block">
              <div class="mr-2 sm:mx-auto sm:mb-2">3</div>
              Confirmation
            </li>
          </ol>
          <nuxt-page />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
const route = useRoute()

const currentStep = computed(() => {
  switch (route.path) {
    case '/onboarding/profile':
      return 1
    case '/onboarding/platform':
      return 2
    case '/onboarding/3':
      return 3
    default:
      return 0
  }
})
</script>
